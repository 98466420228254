import React from "react"
import "./index.scss"

const ArticleTitle = ({ title }) => {
  if (title === "") {
    return <h1 className="article-title">No title</h1>
  } else {
    return <h1 className="article-title">{title}</h1>
  }
  // return <h1 className="article-title">Cookies</h1>
}

export default ArticleTitle
