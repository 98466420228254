import React from "react"
import infobip from "../../../assets/infobip.png"
import "./index.scss"

const ArticleImage = ({ image }) => {
  if (!image || image?.length === 0) {
    return <img key={0} src={infobip} alt="infobip" className="image loaded" />
  } else {
    return (
      <img
        key={1}
        src={`https://api.trebamosiguranje.hr${image?.url}`}
        alt={image.name}
        className="image"
        onLoad={e => e.target.classList.add("loaded")}
      />
    )
  }
}

export default ArticleImage
