import React, { useEffect, useState } from "react"
import strapi from "../api/strapi"
import Header from "../browser/components/Header"
import { showCookieBanner } from "../helpers/functions"
import Cookies from "../universal/components/Cookies"
import Footer from "../universal/components/Footer"
import Loader from "../universal/components/Loader"
import ArticlePageContent from "../views/ArticlePageContent"

const ArticlePage = props => {
  const [pageData, setPageData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [pages, setPages] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const responsePageData = await strapi.get(
          `/stranicas?Slug=${props.params.id}`
        ) // dobijam tocno odredeni slug npr. {id: "druga"}
        const responsePages = await strapi.get(
          `/kategorije-stranicas/${responsePageData.data[0]?.kategorije_stranica.id}`
        ) // dobijam tocno odredu kategoriju stranice
        // if(response.data.length===0){
        //     history.push('/article-not-found')
        // }
        document.title = `Dalmatiko osiguranje | ${responsePageData.data[0]?.Title}`
        setPageData(responsePageData)
        setPages(responsePages)
        setTimeout(() => {
          setLoading(false)
        }, 500)
      } catch (err) {
        setPageData(err)
        console.log(err)
      }
    }
    fetchData()
  }, [props.params.id])

  if (pageData && pages) {
    return (
      <>
        <Header />
        <ArticlePageContent
          loading={loading}
          data={pageData.data}
          pages={pages.data?.Stranice}
        />
        {showCookieBanner() ? <Cookies /> : null}
        <Footer />
      </>
    )
  } else {
    return <Loader />
  }
}

export default ArticlePage
