import React from "react"
import ArticleImage from "../../browser/components/ArticleImage"
import ArticleTitle from "../../browser/components/ArticleTitle"
import Sidebar from "../../browser/components/Sidebar"
import MarkDownContent from "../../universal/components/MarkDownContent"
import "./index.scss"
import "../root_anims.scss"
import Loader from "../../universal/components/Loader"

const ArticlePageContent = ({ loading, data, pages }) => {
  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100vw",
            minHeight: "100vh",
            backgroundImage:
              "linear-gradient(to bottom, #eef5fe 0%, #ffffff 40%)",
            position: "relative",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="article-page-container">
          <Sidebar pages={pages} />

          <div className="article-page-content">
            <ArticleTitle title={data[0]?.Title} />
            <ArticleImage image={data[0]?.Slika[0]} />
            <MarkDownContent content={data[0]?.Sadrzaj} />
          </div>
        </div>
      )}
    </>
  )
}

export default ArticlePageContent
