import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
// import { useLocation } from "react-router-dom"
import "./index.scss"
import { gaOtherClickEvent } from "../../../helpers/functions/googleAnalytics"

const Sidebar = ({ pages }) => {
  let location = window?.location.pathname.replace("/article/", "")

  useEffect(() => {
    location = window?.location.pathname.replace("/article/", "")
  }, [location])

  const activeClassLink = slug => {
    if (slug === location) return true
    else return false
  }

  return (
    <div className="sidebar-holder">
      <ul className="sidebar-list">
        {pages.map(page => (
          <li
            key={page.id}
            className={`sidebar-item ${
              activeClassLink(page.Slug) ? "active" : ""
            }`}
          >
            <Link
              to={`/article/${page.Slug}`}
              className={activeClassLink(page.Slug) ? "active" : ""}
              onClick = {() => gaOtherClickEvent(`Klik na stranicu (${page.Slug}) sa aktivne stranice`)}
            >
              {page.Title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Sidebar
